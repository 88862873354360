import { render, staticRenderFns } from "./Detailinfo.vue?vue&type=template&id=5a2205e4&scoped=true&"
import script from "./Detailinfo.vue?vue&type=script&lang=ts&"
export * from "./Detailinfo.vue?vue&type=script&lang=ts&"
import style0 from "./Detailinfo.vue?vue&type=style&index=0&id=5a2205e4&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a2205e4",
  null
  
)

export default component.exports