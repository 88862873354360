




















































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import VueGallery from "vue-gallery";
// import Gallery from "@/components/UIComponents/gallery.vue";
import { CropVariety, SupplierCropVariety, CaptionFile } from "@/store/models/cropVariety";
import { CropVarietyStore } from "@/store/modules";
import Comments from "@/components/UIComponents/Comments.vue";
import Detailinfoslider from "@/components/UIComponents/Detailinfoslider.vue";

@Component({
  components: {
    gallery: VueGallery,
    comments: Comments,
    Detailinfoslider
  }
})
export default class ListingCards extends Vue {
  cropVarietyId: number = 0;
  pageNum: number = 1;
  loading: boolean = false;

  // @Prop({ default: () => [] })
  // images!: CaptionFile[];
  @Prop({ default: () => [] })
  imagearray!: CaptionFile[];
  @Prop({ default: () => [] })
  captionList!: string[];
  index: number | null = null;
  @Prop({ default: {} as CropVariety })
  cropVariety!: CropVariety;
  currentComment: string = "";

  title: string | null = null;

  @Prop({ default: () => [] })
  suppliers!: SupplierCropVariety;

  slide(id: { index: any; slide: any }) {
    if (this.captionList && this.captionList.length > 0) {
      this.title = this.captionList[id.index];
    }
  }

  changeIndex(id: any) {
    this.index = id;
  }
  checkVariablesExists(variables: any[]) {
    for (let variable of variables) {
      if (
        variable.Data.value != undefined &&
        variable.Data.value != null &&
        variable.Data.value != ""
      ) {
        return true;
      }
    }
    return false;
  }

  routerReplacer() {
    let query: any = {};
    if (this.pageNum) {
      query = String(this.pageNum);
    }
    if (this.$route.name) {
      this.$router
        .replace({
          name: this.$route.name,
          query: query
        })
        .catch(error => {});
    }
    return query;
  }

  async handlePageChange(val: number) {
    this.pageNum = val;
    this.loading = true;
    this.cropVarietyId = Number(this.$route.params.varietySlug);

    let query = this.routerReplacer();
    let params = {
      varietyId: this.cropVarietyId,
      page: query
    };
    this.$router
      .replace({
        query: { page: query },
        params: { varietyId: String(this.cropVarietyId) }
      })
      .catch(error => {});
    await CropVarietyStore.getSupplierCropVariety(params);
    this.loading = false;
  }

  created() {
    if (this.$route.query.page) {
      this.pageNum = Number(this.$route.query.page);
    }
  }
}
