








































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { CropVariety } from "@/store/models/cropVariety";
import { CropVarietyStore, JWTStore, UserStore, CommentStore } from "@/store/modules";
import { CropVarietyComment } from "@/store/models/comment";
import Replies from "@/components/UIComponents/Replies.vue";
import { eventHub } from "@/utils/eventHub";
import moment from "moment";

@Component({
  components: {
    Replies
  }
})
export default class Comments extends Vue {
  @Prop({ default: 0 })
  cropVarietyId!: number;

  currentComment: CropVarietyComment = {} as CropVarietyComment;
  currentReply: CropVarietyComment = {} as CropVarietyComment;
  dialogEditCommentFormVisible = false;
  dialogReplyFormVisible = false;

  get comments() {
    return CommentStore.CommentList;
  }

  get user() {
    return UserStore.User;
  }

  get isLoggedIn() {
    return JWTStore.isAuthenticated;
  }

  emitGloballogin() {
    eventHub.$emit("loginClick");
  }

  getMoment(date: string) {
    return moment(new Date(date)).fromNow();
  }

  removeComment(id: number) {
    this.$confirm("This will permanently delete this comment. Continue?", "Warning", {
      confirmButtonText: "Delete",
      confirmButtonClass: "el-button--danger",
      cancelButtonText: "Cancel",
      showClose: false,
      type: "warning"
    })

      .then(async () => {
        CommentStore.deleteComment(id);
      })

      .catch(() => {
        this.$message({
          type: "info",
          message: "Delete cancelled"
        });
      });
  }

  editComment(id: number) {
    this.$validator.reset();
    this.$validator.errors.clear();
    let commentToEdit = this.comments.filter(x => x.id == id)[0];
    this.currentComment = {
      id: id,
      comment: commentToEdit.comment,
      cropvarietiesid: commentToEdit.cropvarietiesid,
      author: commentToEdit.author
    } as CropVarietyComment;
    this.dialogEditCommentFormVisible = true;
  }

  cancelEdit() {
    this.dialogEditCommentFormVisible = false;
    this.dialogReplyFormVisible = false;
    this.$validator.reset();
    this.$validator.errors.clear();
  }

  reply(commentid: number) {
    this.$validator.reset();
    this.$validator.errors.clear();
    this.currentReply = {
      parentid: commentid,
      cropvarietiesid: this.cropVarietyId
    } as CropVarietyComment;
    this.dialogReplyFormVisible = true;
  }

  async onSubmit() {
    let isValid = await this.$validator.validate("Comment");
    if (isValid) {
      this.currentComment.cropvarietiesid = this.cropVarietyId;
      await CommentStore.saveComment(this.currentComment);
      this.currentComment = {} as CropVarietyComment;
      this.dialogEditCommentFormVisible = false;
    }
  }

  async onReplySubmit() {
    let isValid = await this.$validator.validate("Reply");
    // if (
    //   this.currentReply.comment === undefined ||
    //   this.currentReply.comment.length === 0 ||
    //   this.currentReply.comment === null
    // ) {
    //   return;
    // }
    if (isValid) {
      // if (this.currentReply.parentid && this.currentReply.parentid != null) {
      //   let parentComment = this.comments.filter(x => x.id == this.currentReply.parentid);
      //   if (parentComment.length > 0) {
      //     parentComment[0].reply_count++;
      //   }
      //   CommentStore.increaseReplyCount(this.currentReply.parentid);
      // }
      await CommentStore.saveReply(this.currentReply);
      this.currentReply = {} as CropVarietyComment;
      this.dialogReplyFormVisible = false;
    }
  }
}
